.up-reciept-header h2 {
  text-align: center;
  font-family: "Poppins";
  font-weight: 300;
  color: #224e6b;
  line-height: 48px;
}

.up-reciept-header {
  border-bottom: 1px solid #808080;
  position: relative;
  padding: 6px;
}
/* .go-back{
  background-color: transparent;
  border: transparent;
  padding: 16px;
} */
.up-reciept-header .go-back {
  border: none;
  outline: none;
  background: no-repeat;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 7px;
  cursor: pointer;
 
}

.uploaded-image img {
  width: 100%;
  height: calc(100vh - 225px);
  vertical-align: middle;
  object-fit: cover;
}

.uploaded-image .webcam {
  width: 100%;
  height: calc(100vh - 225px);
  vertical-align: middle;
  object-fit: cover;
}

.single-reward.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.upload-bottons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #ddd;
  gap: 1px;
}
.pic-button
{
  background-color: #ddd;
}

.upload-bottons button, .pic-button button {
  padding: 20px;
  font-size: 14px;
  border: 0;
  outline: none;
  line-height: 1.5;
  background: #2a5673;
  color: #fff;
  cursor: pointer;
}
.upload-bottons button:hover, .pic-button button:hover {
  opacity: 0.9;
}


