.single-input {
  margin: 0px 0;
}

.single-input input {
  border: none;
  outline: none;
  text-align: center;
  border-bottom: 2px solid #ddd;
  padding: 2px 0;
  font-size: 20px;
  box-sizing: border-box;
  max-width: 220px;
  margin: 10px auto 30px;
  display: block;
  background: transparent;
}

.single-input .err-text {
  color: red;
}

.login-content button {
  background: #2a5673;
  line-height: 24px;
  border: 0;
  outline: none;
  border-radius: 7px;
  color: #fff;
  width: 110px;
  margin: auto;
  display: block;
  margin-top: 40px;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
}
.loginFormCenter{
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
border: 2px solid #2a5673;
padding: 24px 16px;
border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;}

  @media screen and (max-width:490px) {
    .loginFormCenter{
      min-width: 80%;
    }
  }