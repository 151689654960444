.empty-history {
  text-align: center;
}

.empty-history img {
  max-width: 200px;
}

.empty-history h3 {
  font-size: 24px;
  color: #5f5f5f;
  margin: 20px 0 40px;
}

.empty-history p {
  color: #808080;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 50px;
  padding: 0 10px;
}

.empty-history button {
  background: #2a5673;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  border: none;
  outline: none;
  padding: 0 20px;
  border-radius: 7px;
  cursor: pointer;
}
