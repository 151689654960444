.rtl,
.rtl * {
  text-align: right;
}

.rtl .header-area,
.rtl .points-view,
.rtl .single-reward,
.rtl .single-request,
.rtl .single-request .content,
.rtl .total-points,
.rtl .single-history .history-info,
.rtl .single-history .history-info .content,
.rtl .single-request p.point,
.rtl .redeem-points .d-flex,
.rtl .redeem-points .d-flex,
.rtl .select-languages a,
.rtl .links-tab .links,
.rtl .redeem-multiple,
.rtl .redeem-output .title {
  flex-direction: row-reverse;
}

.rtl .redeem-output .title {
  justify-content: end;
}

.rtl .history-header .go-back,
.rtl .up-reciept-header .go-back {
  left: calc(100% - 40px);
}
.rtl .history-header .go-back img,
.rtl .up-reciept-header .go-back img {
  transform: rotate(180deg);
}
.rtl button {
  text-align: center;
}

.rtl .total-points img {
  margin: 0 0 0 7px;
}

.rtl .single-reward img {
  margin: 0 0 0 10px;
}

.rtl .single-request p.point img {
  margin: 0 0 0 5px;
}

.rtl .single-request .img img {
  margin: 0 0 0 6px;
}

.rtl .empty-history p {
  text-align: right;
}

.rtl .select-languages a span {
  margin: 0 10px 0 0;
}

.rtl .points-bottom {
  direction: rtl;
}

.rtl .empty-history,
.rtl .empty-history * {
  text-align: center !important;
}
