.form-div {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 45px 0;
}

.single-input-auth {
  width: 100%;
}

.input-wrapper {
  position: relative;
}

.rtl .single-input-auth input {
  text-align: right !important;
}

.rtl input::placeholder {
  text-align: right !important;
}
.userAuthCenterForm{
border: 2px solid #2a5673;
padding: 24px 16px;
border-radius: 16px;
box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.userAuthCenterFormBox{
position: absolute;
top: 50%;
left: 50%;
min-width: 24%;
transform: translate(-50%, -50%);
}
@media screen and (max-width:490px) {
  .userAuthCenterFormBox{
    min-width: 80%;
  }
}
.authStyle {
  display: flex;
  flex-direction: column;


}

.authStyle input {
  border: none;
  outline: none;
  border-bottom: 2px solid #ddd;
  padding: 2px 0;
  font-size: 20px;
  box-sizing: border-box;
  /* max-width: 220px; */
  margin: 10px auto 30px;
  display: block;
  background: transparent;
  border-radius: 0px;
  position: relative;
  width: 100%;
  max-width: 100%;
  line-height: 2ex;
  text-align: left;
}

.authStyle input::placeholder {
  text-align: left;
}

label {
  position: absolute;
  top: 0.2ex;
  z-index: 1;
  left: 2em;
  background-color: white;
  padding: 0 5px;
}

.full-name {
  margin-top: 45px;
}

.occupation {
  margin-top: 40px;
  height: 150px;
  margin-bottom: 40px;
}

.occupation input {
  height: 100%;
}

.btn-div {
  display: flex;
  width: 100%;
}

/* .btn {
  border: 0;
  padding: 15px 30px;
  background-color: rgb(25, 149, 187);
  color: #fff;
  width: 100%;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  cursor: pointer;
  font-size: large;
  border-radius: 4px;
} */

.btn-div button {
  width: 100%;
  background: rgb(25, 149, 187);
  line-height: 24px;
  border: 0;
  outline: none;
  border-radius: 7px;
  color: #fff;
  margin: auto;
  display: block;
  padding: 8px 5px;
  cursor: pointer;
  font-size: 16px;
}

.error-text {
  color: rgba(255, 0, 0, 0.707);
  margin-bottom: 1.5rem;
}
