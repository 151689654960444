@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Passion+One&family=Poppins:ital,wght@0,300;0,400;0,500;1,600&family=Roboto:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.logo {
  display: block;
  margin: auto;
  width: 70px;
}

span.border-middle {
  width: 100px;
  background: #2a5673;
  padding: 2px;
  display: block;
  border-radius: 30px;
  margin: 40px auto;
}

.inner-shadow {
  box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.25),
    inset 0px -2px 3px rgba(0, 0, 0, 0.25);
}
.arabic {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}

.rtl,
.rtl * {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}

.scroller-container {
  height: 100%;
  overflow: auto;
  margin: 4px 0px;
}
.scroller-container::-webkit-scrollbar {
  width: 0;
}

/* full-width on mobile
=================================*/

@media all and (max-width: 767px) {
  .app-home-content,
  .app-content {
    max-width: 100%;
  }
}
