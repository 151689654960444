.not-found {
  max-width: 360px;
  margin: 0 auto;
  padding: 200px 20px;
  text-align: center;
}

.not-found button {
  background: #3c8bbe;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 23px;
  padding: 2px 20px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
}
