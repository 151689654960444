.links-tab {
  padding: 0 20px;
}

.links-tab .links {
  display: flex;
}

.links-tab .links a {
  flex: 1 1;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  color: #c5c5c5;
  position: relative;
  line-height: 1.5;
  padding: 4px;
}

.links-tab .links a.active {
  color: #2a5673;
}

.links-tab .links a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #2a5673;
  left: 0;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  opacity: 0;
  transition: 0.3s;
}

.links-tab .links a.active::before {
  opacity: 1;
}
