main.main-container {
  min-height: 100vh;
  background: linear-gradient(19.77deg, #ffffff 6.43%, #f1f0f0 92.8%);
  /* padding: 50px; */
}

.app-content {
  max-width: 360px;
  margin: auto;
  padding: 50px 20px;
  /* box-shadow: 2px 2px 10px #02021f4d; */
  border-radius: 10px;
}
