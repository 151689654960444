.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.single-reward-skeleton {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: 10px;
  gap: 1.5rem;
}

.single-reward-skeleton .skeleton-img {
  width: 30%;
  height: 60px;
}

.reword-texts {
  width: 70%;
}
.reword-texts .text {
  width: 100%;
  height: 15px;
  margin: 0;
}
.reword-texts .btns {
  width: 40%;
  height: 30px;
  margin: 0;
  margin-top: 0.75rem;
}
