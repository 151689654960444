.single-request-skeleton {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: 10px;
  gap: 1.5rem;
}
.single-request-skeleton .skeleton-img {
  width: 20%;
  height: 40px;
}
.request-texts {
  width: 60%;
}
.request-texts .text {
  width: 100%;
  height: 15px;
  margin: 0;
}
.request-texts .text2 {
  width: 100%;
  height: 10px;
  margin: 0;
  margin-top: 0.75rem;
}

.single-request-skeleton .btns {
  width: 20%;
  height: 30px;
}
