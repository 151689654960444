.request-list {
  padding-top: 5px;
  /* height: calc(100vh - 460px); */
  overflow-y: hidden;
}

.single-request {
  /* background: #fff; */
  padding: 8px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-request .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-request .img img {
  margin-right: 6px;
  max-width: 39px;
}

.single-request h4 {
  color: #2a5675;
  font-size: 14px;
  margin-bottom: 4px;
}

.single-request p.point {
  color: #989898;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.single-request p.point img {
  aspect-ratio: 1;
  width: 12px;
  height: 12px;
  margin: 0 5px 0 0;
}

.single-request .status span {
  background: #ebeef0;
  border: none;
  outline: none;
  line-height: 21px;
  display: block;
  padding: 0px 20px;
  font-size: 12px;
  border-radius: 9px;
  color: #8592a3;
  text-transform: capitalize;
}

.single-request .status .delivered {
  color: #5cad30;
  background: #e8fadf;
}

.single-request .status .processing {
  background: #3c8bbe;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  outline: none;
  line-height: 22px;
  color: #fff;
  padding: 0 15px;
  cursor: pointer;
}
