section.available-points {
  padding: 45px 20px;
}

.points-container {
  background: #366584;
  border-bottom: 2px solid #25485e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding-bottom: 16px;
}

.points-body {
  padding: 10px;
  color: #fff;
}

.points-body h2 {
  font-size: 22px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.points-view {
  display: flex;
  margin: 10px 0;
  align-items: center;
  gap:4px
}

.points-view img {
  width: 20px;
  height: 20px;
  margin: 0 6px 0 0;
}

.points-view strong {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}

.points-body p {
  font-size: 14px;
  margin-bottom: 25px;
}

.points-bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.points-bottom button {
  border: 0;
  outline: none;
  background: #3c8bbe;
  color: #fff;
  padding: 14px;
  line-height: 1.5;
  font-size: 14px;
  cursor: pointer;
  border-radius: 18px;
  padding: 8px;
}

/* skeleton */
.rewords-skeleton {
  width: 30%;
  height: 22px;
}
