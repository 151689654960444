.show-reward-details-component {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  z-index: 9;
  /* transition: all 5s ease-in-out; */
}

.show-reward-details-component .reward-container {
  background: #f1f1f1;
  margin-top: 40px;
  height: calc(100vh - 52px);
  padding: 20px;
  border-radius: 7px 7px 0 0;
  overflow: auto;
}
.redeem-multiple .disabled {
  opacity: 0.5;
  pointer-events: none;
}

.show-reward-details-component .reward-container::-webkit-scrollbar {
  display: none;
}

.reward-container .back-button {
  width: 80px;
  height: 2px;
  background: #a8a8a8;
  margin: 0 auto 20px;
  display: block;
  border-radius: 12px;
  cursor: pointer;
}

.reward-container img {
  max-width: 100%;
}

.reward-container h3 {
  font-size: 23px;
  color: #2a5675;
}

.reward-container p {
  font-size: 13px;
  line-height: 1.5;
  color: #000;
}

.reward-container span {
  font-size: 14px;
  color: #a9a9a9;
  margin: 20px 0 10px;
  display: block;
}

.redeem-points {
  padding: 15px;
  background: #e8e8e8;
  box-shadow: 0px 1px 4px rgba(42, 86, 117, 0.25);
  border-radius: 13px;
}

.redeem-points .d-flex {
  display: flex;
  justify-content: space-between;
}

.redeem-points p {
  color: #989898;
  font-size: 15px;
  margin: 0 0 0 15px;
}

.redeem-multiple {
  display: flex;
  align-items: center;
}

.redeem-multiple button {
  width: 35px;
  height: 35px;
  border: oldlace;
  outline: none;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.redeem-multiple input {
  max-width: 30px;
  margin: 0 5px;
  text-align: center;
  border: 0;
  outline: none;
  background-color: transparent;
  font-size: 20px;
}

/* Chrome, Safari, Edge, Opera */
.redeem-multiple input::-webkit-outer-spin-button,
.redeem-multiple input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.redeem-multiple input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}

.redeem-button button {
  background: #3c8bbe;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 23px;
  padding: 9px 20px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 30px auto 0;
  width: 90%;
  display: block;
}

.redeem-output .title {
  display: flex;
}

.redeem-output .title {
  display: flex;
}

.reward-container h3.title span {
  font-size: 23px;
  color: #2a5675;
  margin: 0;
  display: inline-block;
}
.reward-container h3.title span:nth-child(3) {
  margin: 0 4px;
}

/* SelectContainer styles */
.select-container {
  display: inline-block;
  position: relative;
  width: 100%; /* Set the container width to 100% */
  /* padding: 15px auto; */
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
}

/* CustomSelect styles */
.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #e8e8e8; /* Background color */
  border: none; /* Remove default border */
  padding: 10px;
  font-size: 14px;
  width: 100%; /* Set select width to 100% */
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(42, 86, 117, 0.25);
  border-radius: 13px;
}

/* CustomSelect arrow */
.custom-select::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}
