.OtpFormCenter{
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
border: 2px solid #2a5673;
padding: 24px 16px;
border-radius: 16px;
min-width: 25%;
box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
@media screen and (max-width:490px) {
  .OtpFormCenter{
    min-width: 80%;
  }
}
p.text {
  margin: 20px 0 30px;
}

.rtl input {
  text-align: center ;
}

p.text strong {
  color: #000;
}

.single-link a {
  font-size: 14px;
  color: #295573;
  line-height: 1.2;
}

.single-link {
  text-align: center;
  margin: 10px 0;
}

.otp-form {
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
}

/* Chrome, Safari, Edge, Opera */
.otp-form input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otp-form input[type="number"] {
  -moz-appearance: textfield;
}

.otp-form input {
  max-width: 60px;
  width: 60px;
  border-radius: 50%;
  aspect-ratio: 1;
  padding: 5px;
  font-size: 30px;
  text-align: center;
  border: 1px solid #296189;
  outline: none;
  background: #f3faff;
  height: 60px !important;
  /* text-align: center !important; */
}
/* .otp-form input:focus{
  background-color: white;
} */

button.resend-otp {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}

button.resend-otp-btn {
  font-size: 14px;
  color: blue !important;
  background-color: transparent !important;
  text-decoration: underline;
  width: fit-content !important;
}
button.resend-otp-disabled {
  font-size: 14px;
  color: #808080 !important;
  background-color: transparent !important;
  text-decoration: underline;
  width: fit-content !important;
}

.btn {
  border: 0;
  padding: 15px 30px;
  background-color: rgb(25, 149, 187);
  color: #fff;
}

.btn-div {
  display: flex;
  gap: 15px;
}

button.resend-otp:hover {
  color: #296189;
}
