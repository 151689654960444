.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* background: conic-gradient(#0000 10%, #766df4); */
  background: conic-gradient(#0000 10%, #2a5673);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.custom-loading-container {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.loading-container {
  /* height: 100%; */
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.loading-container img {
  height: 80px;
  width: 80px;
}
