.reward-list {
  background: #f5f5f5;
  padding: 0 20px 0 20px;
  /* height: calc(100vh - 455px); */
  overflow: hidden;
}

.single-reward {
  background: #fff;
  padding: 10px 18px 5px 18px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 4px rgba(42, 86, 117, 0.25);
}
.rewardSearch{
  background-color: #fff;
  border: transparent;
  border-radius: 16px;
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   outline: none;
 

  };

.reward-list .single-reward:first-child {
  margin-top: 10px;
}

.single-reward img {
  max-width: 75px;
  margin-right: 10px;
}

.single-reward h4 {
  color: #2a5675;
  font-size: 16px;
  font-family: "Passion One", cursive;
  margin-bottom: 7px;
  font-weight: 400;
}

.single-reward button {
  background: #3c8bbe;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 23px;
  padding: 2px 20px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
