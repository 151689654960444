.history-body {
  padding: 20px 0;
  height: calc(100vh - 145px);
}

.history-header h2 {
  text-align: center;
  font-family: "Poppins";
  font-weight: 300;
  color: #224e6b;
  line-height: 48px;
}

.history-header {
  border-bottom: 1px solid #808080;
  position: relative;
}

.history-header .go-back {
  border: none;
  outline: none;
  background: no-repeat;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 7px;
  cursor: pointer;
}

.total-points {
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.total-points strong {
  font-size: 22px;
  font-family: "Poppins";
  color: #224e6b;
  letter-spacing: 1px;
}

.total-points img {
  width: 20px;
  height: 20px;
  margin: 0 7px 0 0;
}
