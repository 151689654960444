.single-history {
  padding: 5px 20px;
}

.history-list {
  padding: 20px 0 0 0;
}

.single-history .history-info,
.single-history .history-info .content {
  display: flex;
  align-items: center;
}

.single-history .history-info {
  justify-content: space-between;
}

.single-history h4 {
  font-size: 12px;
}

.single-history .status .pending,
.single-history .status .rejected {
  color: #8592a3;
  font-size: 12px;
  background: #ebeef0;
  line-height: 20px;
  display: block;
  padding: 0 15px;
  border-radius: 20px;
  text-transform: capitalize;
}

.single-history .status .rejected {
  color: #ff3e1d;
  background: #ffe0db;
}

.single-history .status .complete {
  color: #096a00;
  font-family: "Poppins";
}

.single-history .status .fees {
  color: #c20000;
  font-family: "Poppins";
}
