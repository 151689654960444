body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error {
  color: red;
}

.rtl {
  text-align: end;
}

.skeleton {
  background-color: rgba(238, 238, 238, 0.81);
  /* background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee); */
  background-image: -webkit-linear-gradient(
    left,
    #b9b9b9 0px,
    #f4f4f4 40px,
    #bababacf 80px
  );
  background-image: -o-linear-gradient(
    left,
    #cdcdcd 0px,
    #ffffff 40px,
    #c0c0c0 80px
  );
  background-image: linear-gradient(
    90deg,
    #cdcdcdc3 0px,
    #f4f4f4 40px,
    #dadada 80px
  );
  background-size: 250px;
  -webkit-animation: skeleton-animation 1.5s infinite ease-out;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  /* width: 100%;
  height: 50px; */
  margin: 14px 0px 14px;
  animation: skeleton-animation 1.5s ease-in-out infinite;
}

@keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
