header.header-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 3px solid #2a5673;
  /* flex-direction: inherit !important */
}

.language button,
.language a {
  font-size: 16px;
  color: #2a5673;
  border: 0;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.lang-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.lang-link img {
  width: 40px;
}

