.text-20.title {
  font-size: 20px;
}

.google-translate {
  margin-bottom: 40px;
}

.select-languages {
  margin-top: 40px;
}

.select-languages li {
  list-style: none;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.select-languages .list {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  font-size: 20px;
  gap: 10px;
}

.select-languages .list img {
  width: 20px;
  height: 40px;
}
.select-languages a img {
  width: 48px;
  height: 48px;
}

.select-languages a {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  font-size: 20px;
  padding: 1.5rem 0;
  border-bottom: 2px solid #000;
}

.select-languages div {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  font-size: 20px;
  padding: 1.5rem 0;
  border-bottom: 1px solid #000;
  gap: 1rem;
  cursor: pointer;
}

.select-languages div:last-child {
  border-bottom: none;
}

.select-languages a span {
  margin: 0 0 0 15px;
}

.select-languages li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

/* .select-lang-img{
    hei
} */
